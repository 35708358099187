import { useCurrency } from 'hooks/Tokens'
import { useTradeExactIn } from 'hooks/Trades'
import { tryParseAmount } from 'state/swap/hooks'

const useGetCakeBusdLpPrice = () => {
    const cakeAddress = '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    const busdAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    const inputCurrency = useCurrency(cakeAddress)
    const outputCurrency = useCurrency(busdAddress)
    const parsedAmount = tryParseAmount('1', inputCurrency ?? undefined)
    const bestTradeExactIn = useTradeExactIn(parsedAmount, outputCurrency ?? undefined)
    const price = bestTradeExactIn?.executionPrice.toSignificant(6)
    return price ? parseFloat(price) : undefined
}

export default useGetCakeBusdLpPrice
